import React from 'react'

import ProjectLink from '../components/ProjectLink'
import SEO from '../components/Seo'

import { graphql } from 'gatsby'
import { motion } from 'framer-motion'

const container = {
  initial: {},
  exit: {},
  animate: {
    transition: {
      delayChildren: 0.1,
      staggerChildren: 0.1,
    },
  },
}

const fade = {
  initial: { opacity: 0 },
  animate: { opacity: 1, transition: { duration: 0.3, ease: 'easeOut' } },
  exit: { opacity: 0 },
}

function RootIndex({ data }) {
  const siteTitle = data?.contentfulSite?.metaTitle
  const description = data.contentfulSite.metaDescription.childMarkdownRemark.rawMarkdownBody
  const shareImage = data?.contentfulSite?.sharingImage?.fixed?.src

  const posts = data?.contentfulIndex?.projectList

  return (
    <div style={{ background: '#fff' }}>
      <SEO siteTitle={siteTitle} shareImage={shareImage} description={description} />

      <div className="wrapper">
        <motion.ul className="article-list" variants={container} initial="initial" animate="animate">
          {!!posts &&
            posts.length &&
            posts.map(item => {
              return (
                <motion.li key={item.slug} variants={fade}>
                  <ProjectLink {...item} />
                </motion.li>
              )
            })}
        </motion.ul>
      </div>
    </div>
  )
}

export default RootIndex

export const pageQuery = graphql`
  query HomeQuery {
    contentfulSite {
      backToIndexLabel
      links {
        title
        page {
          slug
        }
        externalUrl
      }
      siteTitle {
        json
      }
      siteTitleShort {
        json
      }
      metaDescription {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
      metaTags
      metaTitle
      sharingImage {
        file {
          url
        }
        fixed(width: 1200, height: 630, quality: 80) {
          height
          src
          width
        }
      }
    }
    contentfulAbout {
      socialLinks {
        email
        url
        title
        svgImage {
          file {
            url
            details {
              image {
                height
                width
              }
            }
          }
        }
        svgImageLight {
          file {
            url
            details {
              image {
                height
                width
              }
            }
          }
        }
      }
    }
    contentfulIndex {
      projectList {
        title
        slug
        noise
        image {
          file {
            details {
              image {
                height
                width
              }
            }
          }
          fixed(width: 2800, height: 800, quality: 80) {
            src
            height
            width
            srcSet
            srcSetWebp
            srcWebp
            aspectRatio
          }
          fluid(maxWidth: 2800, maxHeight: 1000, quality: 80) {
            srcWebp
            srcSetWebp
            src
            sizes
            srcSet
            aspectRatio
          }
        }
      }
    }
  }
`
