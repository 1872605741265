import React from 'react'

import Button from '../components/common/Button'

import styled, { css } from 'styled-components/macro'
import { rem } from 'polished'

import noise from '../assets/noise.png'

const ProjectLink = ({ slug, title, noise, image: { file, fixed, fluid } }) => {
  const size = file?.details?.image || {}

  return (
    <Container>
      <Button to={`/${slug}`} icon preset="image">
        <picture>
          <source media={fluid.sizes} srcSet={fluid?.srcSetWebp} type="image/webp" />
          <source media={fluid.sizes} srcSet={fluid?.srcSet} type="image/jpeg" />
          <img src={fixed?.src} {...size} alt={title} />
        </picture>
        {noise === 'on' && <Noise />}
      </Button>
    </Container>
  )
}

export default ProjectLink

const Container = styled.div`
  ${({ theme }) => css`
    position: relative;
    overflow: hidden;

    width: 100%;
    height: 25vh;
    min-height: ${rem(160)};
    max-height: ${rem(380)};

    background-color: rgba(0, 0, 0, 0.1);

    ${theme.media.min('md')} {
      height: 35vh;
      min-height: ${rem(240)};
    }

    * {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    > a,
    button {
      width: 100%;
      height: 100%;

      > div {
        width: 100%;
        height: 100%;
      }
    }
  `};
`

const Noise = styled.div(
  ({ theme }) => css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-image: url(${noise});
    background-repeat: repeat;
    opacity: 0.05;
  `
)
